body, html {
    height: 100%;
  }
  #root { 
    /* Full height */
    height: 100%;
    overflow-x: hidden;
    font: Verdana
  }

  .bg {
    /* The image used */
    background-image: url("yoda-min.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
  
    /* Full height */
    height: 100%;
    box-sizing: border-box;

  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  nav {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .form-background {
    background-color: rgba(245, 245, 245, 0.6)
  }
  
  .no-margin {
    margin-left: 0px;
    margin-right: 0px;
  }
  
  table {
    height: 100%;
    width: 100%;
  }
  
  .image-docker {
    background-color: rgba(212, 201, 201, 0.329);
  }

  .docker-list {
    list-style: none;
    vertical-align: 0vw;
    padding:0;
    margin:0;
    height: 20vw;
    width: 30vw;
    border-radius: 1%;
    overflow-y: scroll;
    scrollbar-color: rgba(212, 201, 201, 0.329)rgba(212, 201, 201, 0.329);
    scroll-margin-bottom: 0;

    background-color: rgba(212, 201, 201, 0.329);
  }

  @media only screen and (max-width: 600px) {
    .docker-list{
      list-style: none;
      vertical-align: 0vw;
      padding:0;
      margin:0;
      width: 50vw;
      background-color: rgba(212, 201, 201, 0.329);

    }
  }
  .docker-item {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;

  
  }
  .docker-item:not(:first-child){
    border-top: 2px solid rgba(82, 57, 57, 0.425);
  }
  .docker-item .name {
    text-align: left;
    size: initial;
    /* width: 80%; */
    /* padding-left: 5% ; */
    text-overflow: ellipsis;
    overflow: hidden
    

  }

  .docker-item .delete-button {
    display: block;
    text-align: middle;
    background-color: rgba(209, 10, 10, 0.87);
    border-radius: 10%;    
    border: 2px solid black;
    position: relative;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    font-size: 2rem;
    cursor: pointer;

    
  }
  .docker-item .delete-button::after{
    content:"\D7";
  }

  /* .selectButton {
    border-radius: 12px 0 12px 0;
    background-color: rgba(131, 154, 255, 0.815);
    border: none;
    box-shadow: rgba(82, 57, 57, 0.425);
    font: normal 1rem Verdana;
    padding: 6px 3px 6px 3px;
  } */

  .selectButton {
  color: rgb(49, 1, 1);
  background: linear-gradient(
		to top,
		rgb(255, 0, 0, 0.7) 15%,
		rgba(254, 254, 254, 1) 45%,
		rgba(254, 254, 254, 1) 50%,
		rgba(254, 254, 254, 1) 55%,
		rgb(255, 0, 0,0.7) 100%
	);
  box-shadow: 0 0 15px #f53a3a65;
  border: none;
  padding: 4px 2% 4px 2%;

  }
  .selectButton:hover{
    box-shadow: 0 0 25px #fd2424;
  }

  .selectButton:focus{
    outline: none;
  }
  .recreateButton {
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
  }
  .form-error {
    font-size: 0.8rem;
    font-style: oblique;
  }

  .form-docker {
    list-style: none;
  }

  .form-row{
    display:flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .form-row > label {
    text-align: left;
  }

  .form-row > input {
    align-content:flex-end;
    position: relative;
  }